module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","zh"],"defaultLanguage":"en","redirect":true,"siteUrl":"https://www.onmygrad.space","i18nextOptions":{"debug":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"detection":{"order":["querystring","cookie","localStorage"]},"fallbackNS":["common"]},"pages":[{"matchPath":"/:lang?/blog-post/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:page?","getLanguageFromPath":true},{"matchPath":"/preview","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-450NEWDBY1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-NKXPBLZ","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-450NEWDBY1"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.onmygrad.space"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
